import React, {useState, useEffect} from 'react'
import { Buffer } from 'buffer';
import {useNavigate} from 'react-router-dom'
import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import emailjs from '@emailjs/browser';

function PaypalCoaching(props) {
  const navigate = useNavigate();
  const amount = props.price;
  const description = props.itemSelling
  const currency = "EUR";
  // const style = {"layout":"vertical"};

  // const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  // eslint-disable-next-line
  const [errorMessage, setErrorMessage] = useState("");
  const [orderID, setOrderID] = useState(false);

  const [clientToken, setClientToken] = useState(null);

  const getAccessToken = async () => {
    const clientId = process.env.REACT_APP_CLIENT_ID;
    const appSecret = process.env.REACT_APP_CLIENT_SECRET;
    const url = 'https://api.paypal.com/v1/oauth2/token';
    const response = await fetch(url, {
      body: "grant_type=client_credentials",
      method: "POST",
      headers: {
        Authorization:
          "Basic " + Buffer.from(clientId + ":" + appSecret).toString("base64"),
      },
    });
    const data = await response.json();
    return data.access_token;
  };

  useEffect(() => {
    const fetchToken = async () => {
      const token = await getAccessToken();
      setClientToken(token);
    };
    fetchToken();
  }, []);

  const initialOptions = {
    "client-id": process.env.REACT_APP_CLIENT_ID,
    currency: "USD",
    intent: "capture",
    "data-client-token": clientToken,
  };


  const createOrder = (data, actions) => {
    return actions.order
        .create({
            purchase_units: [
                {
                  description: `NevskiFit - ${description}`,
                  amount: {
                      currency_code: currency,
                      value: amount,
                  },
                },
            ],
            application_context: {
              shipping_preference: 'NO_SHIPPING'
            }
        })
        .then((orderId) => {
            setOrderID(orderID)
            return orderId;
        });
  };

  const onApprove = (data, actions)  => {
    return actions.order.capture().then(function (details) {
      // const {payer} = details;
      // console.log(payer)
      setSuccess(true)

      const templateParams = {
        name: details.payer.name.given_name,
        email: details.payer.email_address,
        description: description,
      };
      // console.log('test', templateParams)
      emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID_COACHING,
        templateParams,
        'sjPajrIm2IKTbHccg'
        )
        .then(function(response) {
          console.log('SUCCESS!', response.status, response.text);
          alert("Transaction completed, please check your email.")
          navigate('/')

        }, function(error) {
          alert(errorMessage)
          console.log('FAILED...', error);
      });
    });
  };

  const onError = (data, actions) => {
    setErrorMessage("An error occurred with your payment");
  }

  const ButtonWrapper = ({ currency, showSpinner }) => {
    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

    useEffect(() => {
      dispatch({
          type: "resetOptions",
          value: {
            ...options,
            currency: currency,
          },
      });
      // eslint-disable-next-line
    }, [currency, showSpinner]);


    return (<>
      { (showSpinner && isPending) && <div className="spinner" /> }
        <PayPalButtons
          style={{layout: "vertical"}}
          createOrder={createOrder}
          onApprove={onApprove}
          onError={onError}
        />
      </>
    );
  }

  return (
    <div>
      <PayPalScriptProvider
         options={initialOptions}
      >
        <div className='lg:flex lg:justify-between mb-3 lg:flex-col'>
          <li>1x {props.itemSelling}</li>
          <span>Total price: {props.price}€</span>
        </div>
        {/* if onclick only display paypal: */}
        {/* <button onClick={() => setShow(true) } type="submit">Buy now</button> */}
        {/* {show ? (
          <PayPalButtons
            style={{layout: "vertical"}}
            createOrder={createOrder}
            onApprove={onApprove}
            onError={onError}
          />
        ) : null} */}

        {/* <PayPalButtons
          style={{layout: "vertical"}}
          createOrder={createOrder}
          onApprove={onApprove}
          onError={onError}
        /> */}
        <div className='text-center'>
          {success ? (
            <h1>Your Payment has been done successfully please check email</h1>
          ) : null}

          <ButtonWrapper
            currency={currency}
            showSpinner={false}
          />
        </div>
      </PayPalScriptProvider>
    </div>
  )
}

export default PaypalCoaching
