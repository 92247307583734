import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import Nutrition from './pages/Nutrition';
import AboutMe from './pages/AboutMe';
import Training from './pages/Training';
// import ProgramShow from './pages/ProgramShow';
// import BundleShow from './pages/BundleShow';
import CoachingShow from './pages/CoachingShow';
import sales from './sellingApi';
import TryPayPal from './pages/TryPayPal';
import Page404 from './pages/Page404';

function App() {
  // const programs = sales.programmes;
  // const bundles = sales.bundles;
  const coachings = sales.coachings;

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/nutrition" element={<Nutrition />} />
        <Route exact path="/aboutme" element={<AboutMe />} />
        <Route exact path="/training" element={<Training />} />
        {/* {programs.map((program) => (
          <Route key={program.id + program.slug} exact path="/programs/:slug" element={<ProgramShow programs={programs} />} />
        ))}
        {bundles.map((bundle) => (
          <Route key={bundle.id + bundle.slug} exact path="/bundles/:slug" element={<BundleShow bundles={bundles} />} />
        ))} */}
        {coachings.map((coaching) => (
          <Route key={coaching.id + coaching.slug} exact path="/coachings/:slug" element={<CoachingShow coachings={coachings} />} />
        ))}
        <Route exact path="/trypaypal" element={<TryPayPal />} />
        <Route path='*' element={<Page404 />} />
      </Routes>
    </Router>
  );
}

export default App;
