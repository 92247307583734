import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import svenPT from '../assets/images/svenPT.jpg';
import svenTRX from '../assets/images/svenTRX.jpg';

function AboutMe() {
  return (
    <div>
      <Navbar />
      <div className='mx-10 lg:mx-10 xl:mx-40 mt-7 lg:mt-14 mb-20 text-justify'>
        <h2 className='text-xl text-center mb-5 sm:mb-7 lg:mb-10 font-bold'>About me</h2>
        <div className='flex flex-col lg:flex-row'>
          <p className='lg:w-1/2 mb-2.5'>If you're reading this, that means you want to know more about me. First, I want to thank you for your interest. I will gladly share a few words with you about how I ended up here.<br></br><br></br>I have always been a physically active person, and sport has been my passion since a very young age. It was clear for me that I had to pursue something in this field. I went from trying out multiple sports to discovering the gym and the fitness space which stuck with me to this day. I realized how important it is to take care of your body. Everyone has their own insecurities and I can say that fitness and physical activity in general definitely helped me feel better and gain confidence. When we're stuck in our routine it's difficult to come out of it. It means doing something we're not used to, which means uncertainty, which means taking a risk. But it's that step which makes someone grow and learn.</p>
          <img className="lg:w-1/2 lg:ml-10 h-96 my-2.5 lg:mt-0 object-cover" src={svenPT} alt="trainer profile pic" />
        </div>
        <div className='flex flex-col-reverse lg:flex-row'>
          <img className='lg:w-1/3 mr-0 lg:mb-0 my-2.5 lg:mr-10 object-cover' src={svenTRX} alt="trainer with TRX" />
          <p className='my-2.5'>When I first went to a gym I had no real plan and I wasn't really consistent, nor did I watch out what I ate. I probably did nothing right, except the most important thing, I took action. Eventually I started to see the first results and more importantly I realized that everybody starts somewhere, which usually is at the bottom. Throughout the years, learning from mistakes and discovering various training methods, I gained a lot of experience and perfected what I did. "We are what we repeatedly do. Excellence then is not an act but a habit" - Aristotle. <br></br><br></br>During my university years, I studied Sports Science. Classes in Anatomy, Physiology, Psychology, Motor Control, Biomechanics, Neuroscience, etc., built the foundation of what I do today. Later, I took a course in Personal Training to enrich my knowledge even further and become a certified personal trainer. Training with people directly taught me a bunch of practical things and it also showed me that not everybody is the same and that different people need different approaches. On top of that I expanded my expertise by taking an extra course in nutrition. Such a fascinating and probably my favorite topic which goes far beyond just calculating calories and worrying how much protein you eat. Training and nutrition complement each other perfectly so it was important to me to have a deeper understanding of both.<br></br><br></br>If I learned one thing from all this, then it's that my body and my health are the most valuable things in my life. Today I try to inspire and help others who want to adopt a healthier lifestyle and reach their fitness goals. I'll be glad to be the partner you start your new journey with.</p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AboutMe;
