import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons'

function Footer() {
  return (
    <div className='relative bottom-0 w-full flex h-20 bg-footer-blue text-white'>
      <div className='flex items-center m-auto pb-5 sm:pb-0'>
        <a className="pl-3 pr-3 hover:text-xl" target="_blank" rel="noreferrer" href="https://www.instagram.com/nevski.fit/"><FontAwesomeIcon icon={faInstagram} /></a>
        <a className="pl-3 pr-3 hover:text-xl" target="_blank" rel="noreferrer" href="https://www.facebook.com/profile.php?id=100092708290652"><FontAwesomeIcon icon={faFacebook} /></a>
        {/* <a className="pl-3 pr-3 hover:text-xl" target="_blank" rel="noreferrer" href="https://www.tiktok.com/@nevskifit"><FontAwesomeIcon icon={faTiktok} /></a> */}
      </div>
      <p className='absolute bottom-2 right-2 text-xs'>© 2023 by Sven Nguyen-Trong. All rights reserved.</p>
    </div>
  )
}

export default Footer
