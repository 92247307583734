import React from 'react'
import imgRope from '../assets/images/imgrope.jpg'
import { HashLink as Link } from 'react-router-hash-link';
import Button from '../components/Button'

function Page404() {
  return (
    <div style={{backgroundImage: `url(${imgRope})`, backgroundSize: 'cover', backgroundPosition: 'center', height: '100vh'}} className='flex items-center'>
      <div className='m-auto text-white text-center text-2xl leading-loose'>
        <h1 className='text-6xl text-center mb-10'>404 - Page not found</h1>
        <p>Oops! Looks like you've taken a wrong turn.</p>
        <p className='mb-10'>But don't worry, we're here to help you get back on track.</p>
        <Link to="/" className="btn btn-primary"><Button text="Take me to homepage" /></Link>
      </div>
    </div>
  )
}

export default Page404
