import React, { useEffect, useRef, useState } from "react";
import Box from './ProgramBulletBox';

function ProgramExplanation() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const trackRef = useRef(null);
    const boxCount = 5; // Total number of <Box /> components

    useEffect(() => {
        const track = trackRef.current;
        const card = track.children[currentIndex];
        const cardWidth = card.getBoundingClientRect().width + 2;
        const containerWidth = track.parentElement.getBoundingClientRect().width;
    
        // Calculate the offset to center the card
        const offset = (containerWidth - cardWidth) / 2;
    
        // Apply the transform with proper centering
        track.style.transform = `translateX(${Math.round(offset - currentIndex * cardWidth)}px)`;
    }, [currentIndex]);
    

    const handleTouch = (() => {
        let startX = 0;
        return {
            start: (e) => (startX = e.touches[0].clientX),
            end: (e) => {
                const endX = e.changedTouches[0].clientX;
                const diff = startX - endX;

                // Swipe logic: move left or right
                if (diff > 50 && currentIndex < boxCount - 1) {
                    setCurrentIndex((prev) => prev + 1);
                } else if (diff < -50 && currentIndex > 0) {
                    setCurrentIndex((prev) => prev - 1);
                }
            },
        };
    })();

    const handleIndicatorClick = (index) => setCurrentIndex(index);

    return (
        <section className="bg-coaching-blue bg-opacity-5">
            <h2 className="pt-6 text-lg font-bold text-center">
                A PROGRAM MADE FOR YOU
            </h2>

            {/* Static layout for larger screens */}
            <div className='p-10 justify-center static-layout hidden sm:flex flex-row sm:flex-col lg:flex-row gap-1'>
                <Box
                    title="Train from home"
                    description="You don't have time or motivation to go to a commercial gym. This program allows you to train exclusively at home while being accompanied by a personal trainer."
                />
                <Box
                    title="Flexible training schedule"
                    description="Sometimes you have to be flexible and spontaneous. Stop having to plan your training sessions in advance and cancel them last minute. This program allows you to set your training hours freely so that it fits your daily schedule."
                />
                <Box
                    title="No equipment needed"
                    description="You don't have any training equipment or you don't know what to do with it. Whether you have some or not, this program allows you to work out regardless."
                />
                <Box
                    title="No experience needed"
                    description="You probably have no or very little experience when it comes to training so you might think you won't be able to do the exercises or that it will be complicated. This program is made for beginners so everything is adjusted to your level to reach your goal step by step."
                />
                <Box
                    title="Stop counting calories"
                    description="You hate the idea of having to weigh your food and count the calories of everything that you eat. Something that you may have been told to do in the past but got tired of very quickly. This program allows you to improve your nutrition while eating the things that you like without wasting your time counting calories."
                />
            </div>

            {/* Carousel for smaller screens */}
            <div className="carousel-container block sm:hidden pt-5">
                <div className="carousel">
                    <div
                        className="carousel-track"
                        ref={trackRef}
                        onTouchStart={handleTouch.start}
                        onTouchEnd={handleTouch.end}
                    >
                        <Box
                            title="Train from home"
                            description="You don't have time or motivation to go to a commercial gym. This program allows you to train exclusively at home while being accompanied by a personal trainer."
                        />
                        <Box
                            title="Flexible training schedule"
                            description="Sometimes you have to be flexible and spontaneous. Stop having to plan your training sessions in advance and cancel them last minute. This program allows you to set your training hours freely so that it fits your daily schedule."
                        />
                        <Box
                            title="No equipment needed"
                            description="You don't have any training equipment or you don't know what to do with it. Whether you have some or not, this program allows you to work out regardless."
                        />
                        <Box
                            title="No experience needed"
                            description="You probably have no or very little experience when it comes to training so you might think you won't be able to do the exercises or that it will be complicated. This program is made for beginners so everything is adjusted to your level to reach your goal step by step."
                        />
                        <Box
                            title="Stop counting calories"
                            description="You hate the idea of having to weigh your food and count the calories of everything that you eat. Something that you may have been told to do in the past but got tired of very quickly. This program allows you to improve your nutrition while eating the things that you like without wasting your time counting calories."
                        />
                    </div>
                </div>

                {/* Carousel navigation */}
                <div className="carousel-navigation mb-10">
                    <button
                        className="nav-btn prev-btn"
                        aria-label="Previous"
                        onClick={() => setCurrentIndex((prev) => Math.max(prev - 1, 0))}
                        disabled={currentIndex === 0}
                    >
                        &lt;
                    </button>
                    <div className="carousel-indicators">
                        {Array.from({ length: boxCount }).map((_, index) => (
                            <div
                                key={index}
                                className={`indicator ${
                                    currentIndex === index ? "active" : ""
                                }`}
                                onClick={() => handleIndicatorClick(index)}
                            ></div>
                        ))}
                    </div>
                    <button
                        className="nav-btn next-btn"
                        aria-label="Next"
                        onClick={() =>
                            setCurrentIndex((prev) => Math.min(prev + 1, boxCount - 1))
                        }
                        disabled={currentIndex === boxCount - 1}
                    >
                        &gt;
                    </button>
                </div>
            </div>
        </section>
    );
}

export default ProgramExplanation
