import React, {useState, useEffect} from 'react'
import { Buffer } from 'buffer';
import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";

function TryPayPal() {
  const amount = 1;
  const description = 'trying paypal'
  const currency = "EUR";
  // const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [orderID, setOrderID] = useState(false);

  const [clientToken, setClientToken] = useState(null);

  const getAccessToken = async () => {
    const clientId = process.env.REACT_APP_CLIENT_ID;
    const appSecret = process.env.REACT_APP_CLIENT_SECRET;
    const url = 'https://api.paypal.com/v1/oauth2/token';
    const response = await fetch(url, {
      body: "grant_type=client_credentials",
      method: "POST",
      headers: {
        Authorization:
          "Basic " + Buffer.from(clientId + ":" + appSecret).toString("base64"),
      },
    });
    const data = await response.json();
    return data.access_token;
  };

  useEffect(() => {
    const fetchToken = async () => {
      const token = await getAccessToken();
      setClientToken(token);
    };
    fetchToken();
  }, []);

  const initialOptions = {
    "client-id": process.env.REACT_APP_CLIENT_ID,
    currency: "USD",
    intent: "capture",
    "data-client-token": clientToken,
  };


  const createOrder = (data, actions) => {
    return actions.order
        .create({
            purchase_units: [
                {
                  description: `NevskiFit - ${description}`,
                  amount: {
                      currency_code: currency,
                      value: amount,
                  },
                },
            ],
            application_context: {
              shipping_preference: 'NO_SHIPPING'
            }
        })
        .then((orderId) => {
            setOrderID(orderID)
            return orderId;
        });
  };

  const onApprove = (data, actions)  => {
    setSuccess(true)
  };

  const onError = (data, actions) => {
    setErrorMessage("An error occurred with your payment");
  }

  const ButtonWrapper = ({ currency, showSpinner }) => {
    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

    useEffect(() => {
      dispatch({
          type: "resetOptions",
          value: {
            ...options,
            currency: currency,
          },
      });
      // eslint-disable-next-line
    }, [currency, showSpinner]);


    return (<>
      { (showSpinner && isPending) && <div className="spinner" /> }
        <PayPalButtons
          style={{layout: "vertical"}}
          createOrder={createOrder}
          onApprove={onApprove}
          onError={onError}
        />
      </>
    );
  }

  return (
    <div>
      <PayPalScriptProvider
         options={initialOptions}
      >
        <div className='lg:flex lg:justify-between mb-3 lg:flex-col'>
          <li>1x {description}</li>
          <span>Total price: {amount}€</span>
        </div>

        <div className='text-center'>
          {success ? (
            <h1>Your Payment has been done successfully please check email</h1>
          ) : null}

          <ButtonWrapper
            currency={currency}
            showSpinner={false}
          />
        </div>
      </PayPalScriptProvider>
    </div>
  )
}

export default TryPayPal
