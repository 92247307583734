import React from 'react'
// import Tooltip from "./Tooltip";

function ProgramExplanationBox(props) {
    return (
        <div className='max-w-xs sm:max-w-max sm:w-full lg:w-1/4 p-5 justify-center border border-yellow text-black rounded-lg'>
            {/*<Tooltip text={props.description} children={props.title}></Tooltip>*/}
            <p className=''><b>{props.title}</b></p>
            <p className=''>{props.description}</p>
        </div>
    )
}
export default ProgramExplanationBox

