import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import ball from '../assets/images/ballLunge.jpg';
import kbSquat from '../assets/images/kettlebellSquat.jpg';

function Nutrition() {
  return (
    <div>
      <Navbar />
      <div className='mx-10 lg:mx-10 xl:mx-40 mt-7 lg:mt-14 mb-20 text-justify'>
        <h2 className='text-xl text-center mb-5 sm:mb-7 lg:mb-10 font-bold'>Training</h2>
        {/*<div className='flex flex-col lg:flex-row mb-4 lg:items-center'>*/}
        <p className='mb-5'>Being fit and healthy starts with physical activity. Humans are supposed to move, not to sit on a couch. When you're young you naturally have more energy and everything feels easier but as you grow older you'll realize that even simple every day tasks get more and more difficult, you're tired quicker and everything is more demanding on your body.</p>
        <p className='mb-5'>That is only if you don't train. You can drastically slow down the aging process if you keep yourself physically fit. The major components of physical fitness are strength, muscle mass, mobility, flexibility, balance and cardiovascular health. </p>
        <div className="flex flex-col-reverse lg:flex-row items-stretch">
          <div className='lg:w-3/5 lg:my-0 items-stretch'>
            <p className='mb-5'><strong>Strength: </strong>We use our strength to push, pull, jump, hold onto something physically, pick up a heavy object from the floor, carry something  and in many other situations we face in our lives. A lack of strength will definitely make your life harder.</p>
            <p className='mb-5'>Did you know that grip strength is related to longevity and slowing down the aging process?</p>
            <p className='mb-5'><strong>Muscle mass: </strong>The first step to gain strength is of course to gain muscle mass. Developing our muscles however will not only make us stronger and look better but will even improve our cardiovascular health. Our muscle will absorb and utilise the oxygen it gets from our blood better which leads to lower blood pressure.</p>
            <p className='mb-5'>Did you now that muscle is more energy consuming than fat and the more muscle mass we have the more calories we burn without doing anything?</p>
          </div>
          <img className="lg:w-2/5 object-cover mb-5 lg:ml-5 lg:mb-5" id="kettleBellSquat" src={kbSquat} alt="kettlebell squat"/>
        </div>
        <p className='mb-5'><strong>Mobility: </strong>Having a strong body is important but we also need to be able to move it properly. Mobility means the capacity to freely move our joints and our body in general in all possible directions. Our body is required to execute such tasks constantly. A better mobility will give us an easier time doing so and possibly prevent injury.</p>
        <p className='mb-5'>Did you know that our body can move in three different planes; the frontal, the lateral and the transverse plane?</p>
          <p className='mb-5'><strong>Flexibility: </strong>Closely linked to mobility, flexibility basically means the stretchability of your muscles. If you want to be mobile you somewhat need to be flexible as well. Again better flexibility will help us accomplish everyday tasks easier and possibly prevent injury. </p>
          <p className='mb-5'>Did you know that most people have tight hamstrings because they sit too much?</p>
          <p className='mb-5'><strong>Balance: </strong>This is probably the one that gets overlooked the most but without balance we wouldn't do very much, not even walk. In fact, you can clearly see this with elderly people. The lack of balance is often a major problem in our overall health as we age.</p>
          <p className='mb-5'>Did you know that falls caused by a lack of balance (and also strength) are one of the leading causes of injury amongst older adults? </p>
        <div className="flex flex-col-reverse lg:flex-row items-center lg:items-start">
          <img className="lg:w-2/5 object-cover mb-5 lg:mr-5 lg:mb-0" id="ballImg" src={ball} alt="ball lunges"/>
          <div className='lg:w-3/5 lg:my-0 items-start'>
            <p className='mb-5'><strong>Cardiovascular health: </strong>Of course we can't  forget the most important muscle in our body, the heart. We can deliberately train it by doing a prolonged effort that gets our heart rate up like going for a run or playing sports (football, basketball, tennis, …) </p>
            <p className='mb-5'>Did you know that even just going for a little walk once a day reduces the risk of heart disease? </p>
          </div>
        </div>
        <p className='mb-5 lg:my-5'>With this being said, we didn't even talk about the visual benefits you'll see looking at yourself in the mirror when you gain muscle and/or lose fat thanks to your training (and nutrition). Working out will obviously get you a more aesthetically pleasing physique and we all know that if you look better you feel better.</p>
        <p className='mb-5'>Physical fitness is the absolute baseline of mental fitness. If you're mentally not in a good place, the first step is to work on the physical aspect of health. Without that, it will be very difficult to work on the mental aspect. In fact, your mental health will naturally improve with your physical health. “Strong body, strong mind”, is what they say. And it is certainly true. The confidence boost you get when you realize that you look and feel better will change your daily life by itself.</p>
        <p className='mb-5'>The sooner you start, the better it is, and it's never too late.</p>
      </div>
      <Footer />
    </div>
  )
}

export default Nutrition
